import { createHttpClient } from '@/api/analytics/httpClient'

const client = createHttpClient(process.env.VUE_APP_ANALYTICS_URL)

const getReportAbcV1 = (options = {}, dateFrom, dateTo) => client.get('/api/v1/reports/abc/download',
  { params: { dateFrom, dateTo }, ...options })

const dynamicPositionProductsV1 = (options = {}, dateFrom, dateTo) => client.get('/api/v1/reports/dynamic-position-products/download',
  { params: { dateFrom, dateTo }, ...options })

const summaryOfTurnoverShortV1 = (options = {}, dateFrom, dateTo) => client.get('/api/v1/reports/summary-of-turnover-short/download',
  { params: { dateFrom, dateTo }, ...options })

const productsOrderedV1 = (options = {}, dateFrom, dateTo) => client.get('api/v1/reports/products-ordered/download',
  { params: { dateFrom, dateTo }, ...options })

const concurrentWithPriceV1 = (options = {}) => client.get('/api/v1/reports/concurrent_with_price/download',
  { ...options })

const productConcurrentCountV1 = (options = {}) => client.get('/api/v1/products/concurrent_count',
  { ...options })

const templateUploadConcurrentV1 = (options = {}) => client.get('/api/v1/reports/template_upload_concurrent/download',
  { ...options })

const uploadConcurrentV1 = (file, options = {}) => {
  const formData = new FormData()
  formData.append('file', file)

  return client.post('/api/v1/reports/upload_concurrent', formData, {
    ...options
  })
}

export default {
  getReportAbcV1,
  dynamicPositionProductsV1,
  summaryOfTurnoverShortV1,
  productsOrderedV1,
  concurrentWithPriceV1,
  productConcurrentCountV1,
  templateUploadConcurrentV1,
  uploadConcurrentV1
}
