<template>
  <x-btn
    v-bind="$attrs"
    :color="color"
    prepend="upload"
    :loading="isLoading"
    :metrics="metrics"
    @click="onClick"
  >
    <slot>
      Добавить файл
    </slot>
    <input
      ref="input"
      type="file"
      style="display: none"
      :accept="accept"
      @change="handleFiles"
    >
  </x-btn>
</template>

<script>
import { downloadFileFromResponse } from '@/utils/downloadFileFromResponse'
import toastNotification from '@/utils/toast-notification'

export default {
  name: 'UploadButton',

  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    accept: String,
    action: Function,
    metrics: String
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    async onClick () {
      this.$refs.input.click()
    },
    async handleFiles () {
      this.isLoading = true
      try {
        const file = this.$refs.input.files[0]
        const res = await this.action(file)
        downloadFileFromResponse(res)
        toastNotification.success('файл загружен')
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toastNotification.error('ошибка')
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .download
    &__icon
      margin-right 5px
      color currentColor

      &_single
        margin-right 0
</style>
