import router from '@/router'

export function getRouteQuery (name) {
  const data = router.currentRoute.query[name]

  return Array.isArray(data)
    ? data[0]
    : data
}

export function setRouteQuery (name, val) {
  router.replace({
    query: {
      ...router.currentRoute.query,
      [name]: val
    }
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e)
  })
}
