<template>
  <div>
    <div class="headline-5 mb-3">
      {{ title }}
    </div>

    <div
      v-if="alert || $slots.alert"
      class="mb-3"
    >
      <slot name="alert">
        <x-alert>{{ alert }}</x-alert>
      </slot>
    </div>

    <template v-if="isPermitByTariff">
      <div class="row">
        <div
          v-if="datePicker"
          class="col-xl-3 mb-2 mb-xl-0"
        >
          <x-label>Выберите период для скачивания</x-label>
          <datepicker
            :max-date="maxDate"
            :value="datePeriod"
            border
            @change="onDateChange"
          />
        </div>

        <div class="col-xl-6 d-flex align-self-end align-center">
          <slot name="before-download" />
          <download-button
            color="gray"
            :metrics="metrics"
            :action="download"
          >
            Скачать
          </download-button>
        </div>
      </div>

      <slot name="bottom" />
    </template>
    <not-permit-by-tariff v-else />

    <x-divider
      v-if="divider"
      class="my-5"
    />
  </div>
</template>

<script>
import Datepicker from '@/components/Interface/Datepicker'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff'
import DownloadButton from '@/components/Interface/DownloadButton.vue'

export default {
  name: 'ReportsSection',

  components: {
    NotPermitByTariff,
    Datepicker,
    DownloadButton
  },

  props: {
    title: String,
    alert: String,
    metrics: String,
    download: Function,
    datePicker: {
      type: Boolean,
      default: true
    },
    divider: {
      type: Boolean,
      default: true
    },
    permissionKey: {
      type: String,
      required: true
    },
    datePeriod: {
      type: Object,
      default: () => ({
        start: null,
        end: null
      })
    }
  },

  inject: ['maxDate'],

  computed: {
    isPermitByTariff () {
      return this.$user.isPermitByTariff(this.permissionKey)
    }
  },

  methods: {
    onDateChange (range) {
      this.$emit('change-date', range)
    }
  }
}
</script>
