import axios from 'axios'

import {
  getApiToken,
  removeApiToken,
  redirectOnPassport
} from '@/utils/system'
import ROUTES from '@/router/ROUTES.js'
import responseCodes from '@/constants/response-codes'
import { isCustomError } from '@/utils/isCustomError'
import ToastNotification from '@/utils/toast-notification'

function isNetworkError (err) {
  return !!err.isAxiosError && !err.response
}

function isAxiosError (err) {
  return err.isAxiosError
}

export const createHttpClient = (baseUrl) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl
  })

  axiosInstance.interceptors.request.use(
    function (config) {
      const token = getApiToken()

      if (token) {
        config.headers.Authorization = token
      }

      return config
    },

    function (error) {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    function (res) {
      return isCustomError(res)
        ? Promise.reject(res)
        : res
    },

    err => {
      if (isNetworkError(err)) {
        ToastNotification.error('ошибка сети')
        return
      }

      if (
        isAxiosError(err) &&
        err.response?.status === responseCodes.unauthorized
      ) {
        removeApiToken()
        redirectOnPassport()
      } else if (
        isAxiosError(err) &&
        err.response?.status === responseCodes.paymentRequired
      ) {
        window.location.replace(`${ROUTES.TARIFFS.path}?unpaid=true`)
      }
      return Promise.reject(err)
    }
  )

  return axiosInstance
}
