<template>
  <x-page
    :title="orderTitle"
    card
  >
    <shop-limit-alert class="mb-1" />
    <template
      v-if="hasShops && !isManager"
      #title-row
    >
      <div class="col-xl-1 d-flex justify-end">
        <x-label
          class="mb-0"
          html-for="shop"
          size="s"
        >
          Магазин
        </x-label>
      </div>
      <div class="col-xl-3">
        <x-select
          id="shop"
          v-model="currentShop"
          :options="shops"
          track-by="id"
          label="name"
        />
      </div>
    </template>
    <no-shops
      v-if="!hasShops && !isManager"
      data="для отчетов"
    />
    <shops-availability-placeholder v-else-if="!isCurrentShopAvailable && !isManager" />
    <template v-else-if="!isManager">
      <ReportsSection
        :permission-key="PERMISSION_KEY.ANALYTICS_ABC_REPORT"
        :download="bindDownload(reportsApi.getReportAbcV1)"
        title="ABC анализ по товарам"
        alert="Данный анализ упорядочит ваш ассортимент по степени важности! Вы поймете, какие товары приносят основной доход, а какие – малую ценность, тем самым сможете повысить эффективность своего бизнеса."
        metrics="analytics_reports_abc_report"
        :date-period="currentPeriod"
        @change-date="onDateChange"
      />
      <ReportsSection
        :permission-key="PERMISSION_KEY.ANALYTICS_POSITIONS"
        :download="bindDownload(reportsApi.dynamicPositionProductsV1)"
        title="Динамика позиций по товарам"
        alert="Данный анализ позволит определить движение ассортимента в поисковой и категорийной выдаче за период"
        metrics="analytics_reports_dynamic_positions"
        :date-period="currentPeriod"
        @change-date="onDateChange"
      />
      <ReportsSection
        :permission-key="PERMISSION_KEY.ANALYTICS_INDICATORS"
        title="Динамика остатков по товарам"
        :download="bindDownload(reportsApi.summaryOfTurnoverShortV1)"
        metrics="analytics_reports_summary_turnover"
        :date-period="currentPeriod"
        @change-date="onDateChange"
      >
        <template #alert>
          <x-alert>
            <div>
              Данный анализ позволит определить движение остатков за период, скорость продаж, а также упущенную выручку. Для детального анализа оборачиваемости товара, воспользуйтесь
              <x-link :to="{ name: PRODUCT_LIST_VIEW }">
                Отчетом по прогнозу поставок
              </x-link>
            </div>
          </x-alert>
        </template>
      </reportssection>
      <ReportsSection
        :permission-key="PERMISSION_KEY.ANALYTICS_PRICES"
        title="Сравнение цен с конкурентами"
        alert="Данный анализ позволит сравнить цены на товар с добавленными конкурентами"
        :download="bindDownload(reportsApi.concurrentWithPriceV1, { period: false })"
        :date-picker="false"
        metrics="analytics_reports_competitor_price"
      >
        <template #before-download>
          <span v-if="competitorsDataLoading">
            Загружаем кол-во конкурентов...
            <span class="gray-darker--text mr-2">(и товаров...)</span>
          </span>
          <span v-if="competitorsData && !competitorsDataLoading">
            Кол-во добавленных конкурентов: {{ Number(competitorsData.concurrentCount).toLocaleString() }}
            <span class="gray-darker--text mr-2">
              {{ productAmountPlural }}
            </span>
          </span>
        </template>
        <template #bottom>
          <div class="row">
            <x-alert class="my-3">
              Вы можете воспользоваться массовым добавлением ссылок на конкурентов через XLS файл с помощью формы ниже
            </x-alert>
            <div class="col-xl-3 align-self-center">
              <x-circle-label class="mr-3">
                1
              </x-circle-label>
              <span>Скачайте файл шаблона</span>
            </div>
            <div class="col-xl-9">
              <download-button
                metrics="analytics_reports_template_upload_competitor"
                :action="bindDownload(reportsApi.templateUploadConcurrentV1, { period: false })"
              >
                Скачать шаблон добавления конкурентов
              </download-button>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-xl-3 d-flex align-self-center align-center">
              <x-circle-label class="mr-3">
                2
              </x-circle-label>
              <span>Загрузите шаблон с добавленными ссылками на конкурентов</span>
            </div>
            <div class="col-xl-9">
              <upload-button
                outline
                color="primary"
                accept=".xlsx"
                metrics="analytics_reports_template_upload_competitor_add"
                :action="bindUpload(reportsApi.uploadConcurrentV1)"
              />
            </div>
          </div>
        </template>
      </ReportsSection>
      <ReportsSection
        :permission-key="PERMISSION_KEY.ANALYTICS_INDICATORS"
        title="Динамика продаж по товарам"
        alert="Данный анализ позволит увидеть продажи товаров с разбивкой по дням"
        :download="bindDownload(reportsApi.productsOrderedV1)"
        :divider="false"
        metrics="analytics_reports_products_ordered"
        :date-period="currentPeriod"
        @change-date="onDateChange"
      />
    </template>
    <template v-else>
      <ul class="filters">
        <li class="filters__item">
          <x-form-item
            name="Выберите магазин"
            label="Выберите магазин"
          >
            <x-select
              v-model="selectShop"
              shadow
              track-by="id"
              label="label"
              :options="shops"
              :searchable="false"
              :allow-empty="false"
              placeholder="Магазин"
              @select="option => $emit('change:show-by', option)"
            />
          </x-form-item>
        </li>
        <li class="filters__item filters__item_content_period">
          <x-form-item
            name="Выберите период"
            label="Выберите период"
          >
            <datepicker
              :max-date="new Date()"
              :default-period="filtredPeriod"
              :value="period"
              @change="date => period = date"
              @change-default-period="p => filtredPeriod = p"
            />
          </x-form-item>
        </li>

        <li class="filters__item">
          <x-form-item
            name="Выберите отчёт"
            label="Выберите отчёт"
          >
            <x-select
              v-model="selectReport"
              shadow
              track-by="id"
              label="label"
              :options="reportOptions"
              :searchable="false"
              :allow-empty="false"
              placeholder="Отчёт"
            />
          </x-form-item>
        </li>
        <li class="filters__item download-btn">
          <x-btn
            type="button"
            block
            :loading="isLoading"
            :disabled="!selectShop || !selectReport"
            @click="downloadReport"
          >
            Заказать
          </x-btn>
        </li>
      </ul>
    </template>
  </x-page>
</template>

<script>
import ShopLimitAlert from '@/components/NotPermit/ShopLimitAlert'
import NoShops from '@/components/Interface/NoShops'
import ShopsAvailabilityPlaceholder from '@/views/Reports/ShopsAvailabilityPlaceholder'
import ReportsSection from '@/views/Reports/ReportsSection'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import dateFormat from '@/utils/date-formatter'
import toastNotification from '@/utils/toast-notification'
import reportsApi from '@/api/analytics/reportsApi'
import { setRouteQuery, getRouteQuery } from '@/utils/routeQuery'
import { DAY_IN_MS } from '@/constants/timings'
import { createNamespacedHelpers } from 'vuex'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import ROUTES from '@/router/ROUTES'
import marketplaceCodes from '@/constants/marketplace-codes'
import createCountFormatter from '@/utils/count-formatter'
import XCircleLabel from '@/components/Common/XCircleLabel'
import DownloadButton from '@/components/Interface/DownloadButton'
import UploadButton from '@/components/Interface/UploadButton'
import Datepicker from '@/components/Interface/Datepicker.vue'
// import CalendarPeriods from '@/constants/calendar-periods'
// import { downloadFileFromResponse } from '@/utils/downloadFileFromResponse'

const { mapActions, mapGetters } = createNamespacedHelpers('marketplaces')
const { mapActions: mapReportActions } = createNamespacedHelpers('reports')
const { mapState: mapProfileState } = createNamespacedHelpers('profile')
const { mapActions: mapExportActions } = createNamespacedHelpers('exports')

const DOWNLOAD_FORMAT = 'YYYY-MM-DD'
const DATE_FROM = 'dateFrom'
const DATE_TO = 'dateTo'
const SHOP = 'shop'
// const START_OF_COLLECTION = '2022-02-01'
const WEEK_AGO_MS = Date.now() - DAY_IN_MS * 7

const today = new Date()

// const MAX_DATE = new Date(today.setTime(today.getTime() - DAY_IN_MS))

export default {
  name: 'Reports',

  components: {
    DownloadButton,
    UploadButton,
    ReportsSection,
    ShopsAvailabilityPlaceholder,
    NoShops,
    ShopLimitAlert,
    XCircleLabel,
    Datepicker
  },

  data: () => ({
    reportsApi,
    PERMISSION_KEY,
    currentPeriod: {
      start: dateFormat(WEEK_AGO_MS, DOWNLOAD_FORMAT),
      end: dateFormat(today, DOWNLOAD_FORMAT)
    },
    // filtredPeriod: CalendarPeriods.CURRENT_WEEK,
    filtredPeriod: {
      start: dateFormat(WEEK_AGO_MS, DOWNLOAD_FORMAT),
      end: dateFormat(today, DOWNLOAD_FORMAT)
    },
    currentShop: {},
    PRODUCT_LIST_VIEW: ROUTES.PRODUCT_LIST.name,
    competitorsData: null,
    competitorsDataLoading: false,
    shopOptions: [],
    reportOptions: [],
    selectShop: null,
    selectReport: null,
    period: {
      start: dateFormat(WEEK_AGO_MS, DOWNLOAD_FORMAT),
      end: dateFormat(today, DOWNLOAD_FORMAT)
    },
    isLoading: false
  }),

  watch: {
    currentShop (value) {
      this.fetchCompetitorsData()
      setRouteQuery(SHOP, value.id)
    }
  },

  computed: {
    ...mapGetters(['filteredShopList', 'hasShops']),
    ...mapProfileState(['user']),

    productAmountPlural () {
      const getPlural = createCountFormatter({ few: 'товаров', one: 'товара', two: 'товаров' })
      const number = Number(this.competitorsData?.productWithConcurrentCount)
      return `(у ${number.toLocaleString()} ${getPlural(number)})`
    },

    /* minDate () {
      return new Date(getDate(START_OF_COLLECTION))
    }, */

    shops () {
      return this.filteredShopList.map(shop => ({
        ...shop,
        label: shop.name,
        $icon: getMarketplaceIcon(shop.marketplace_code)
      }))
    },

    isCurrentShopAvailable () {
      return [marketplaceCodes.OZON, marketplaceCodes.WILDBERRIES]
        .includes(this.currentShop.marketplace_code)
    },

    orderTitle () {
      return this.isManager ? 'Отчеты' : 'Маркетинговые отчеты'
    },

    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    }
  },

  provide () {
    return {
      maxDate: today
    }
  },

  async created () {
    await this.getShopList()
    if (!this.isManager) {
      const from = getRouteQuery(DATE_FROM)
      const to = getRouteQuery(DATE_TO)
      const shopId = getRouteQuery(SHOP)

      if (from) this.currentPeriod.start = from
      if (to) this.currentPeriod.end = to
      if (shopId) {
        this.currentShop = this.shops.find(shop => shop.id === Number(shopId)) || this.shops?.[0]
      } else {
        this.currentShop = this.shops?.[0]
      }

      this.fetchCompetitorsData()
    } else {
      const data = await this.getReportList()
      for (const key in data) {
        this.reportOptions.push({
          id: key,
          label: data[key]
        })
      }
    }
  },

  methods: {
    ...mapActions(['getShopList']),
    ...mapReportActions(['getReportList', 'createReport']),
    ...mapExportActions(['getExports', 'getExportFile']),

    async fetchCompetitorsData () {
      this.competitorsDataLoading = true
      this.competitorsData = (await reportsApi.productConcurrentCountV1({ headers: { 'X-XW-Shop-Id': this.currentShop?.id } })).data?.data
      this.competitorsDataLoading = false
    },

    bindDownload (request, options = { period: true }) {
      const from = dateFormat(this.currentPeriod.start, DOWNLOAD_FORMAT)
      const to = dateFormat(this.currentPeriod.end, DOWNLOAD_FORMAT)
      const requestOptions = { responseType: 'blob', headers: { 'X-XW-Shop-Id': this.currentShop?.id } }

      return () => options.period
        ? request(requestOptions, from, to)
        : request(requestOptions)
    },

    async downloadReport () {
      this.isLoading = true
      await this.createReport({
        shop_id: this.selectShop.id,
        from: this.period.start,
        to: this.period.end,
        report_type: this.selectReport?.id
      })
      this.isLoading = false
      toastNotification.success('отчет заказан. Скачать отчет возможно в разделе Списки на выгрузку')
    },

    bindUpload (request) {
      const requestOptions = {
        responseType: 'blob',
        headers: {
          'X-XW-Shop-Id': this.currentShop?.id,
          'Content-Type': 'multipart/form-data'
        }
      }

      return (file) => request(file, requestOptions)
    },

    onDateChange (range) {
      this.currentPeriod = range
      setRouteQuery(DATE_FROM, range.start)
      setRouteQuery(DATE_TO, range.end)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .filters
    display flex
    margin 0
    padding 0
    list-style none
    margin-left -10px

    @media (max-width 768px)
      display block
      margin-left 0

    &__item
      flex-basis 160px
      margin-left 10px

      @media (max-width 768px)
        margin-left 0
        margin-bottom 15px

      &_content_period
        flex-basis 200px

      &:last-child
        @media (max-width 768px)
          margin-bottom 0

      &.download-btn
        align-self: flex-end

    &__option
      display flex
      align-items center

    &__option-icon
      width 14px
      margin-right 4px
</style>
